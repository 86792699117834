import React, { Component } from 'react';

export default class Counter extends Component {

  render() {
    if (this.props.counter === this.props.total) return <span />;

    return (
      <div className="Counter">
        <p>Aufgabe {this.props.counter + 1} von {this.props.total}:</p>
      </div>
    );
  }

}