import React, { Component } from 'react';
import Game from './game.jsx';
import Settings from './settings.jsx';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: 'start',
      settings: {
        numberRange: [{min: 0, max: 10}, {min: 0, max: 10} ],
        resultRange: {min: 0, max: 100},
        arithmeticRange: ["*", "/"],
        questions: 20,
        randomHide: false,
        noten: [
          {min: 92, max: 100, note: 1},
          {min: 81, max: 91, note:2},
          {min: 67, max: 80, note: 3},
          {min: 50, max: 66, note: 4},
          {min: 30, max: 49, note: 5},
          {min: 0, max: 29, note: 6}
        ]
      }
    };
  }
  
  updateSettings(newSettings) {
    this.setState({settings: newSettings, screen: 'start'});
  }
  cancelSettings() {
    this.setState({screen: 'start'});
  }

  clickBeaver() {
    this.setState({screen: 'game'});
  }
  
  clickMenu() {
    this.setState({screen: 'settings'});
  }

  gameLayout() {
    if (this.state.screen === 'start') {
      return (
        <div className="startPic">
          <img className="beaver" src="./images/biber.png" height="45%" width="45%" alt="" onClick={this.clickBeaver.bind(this)} />
          <p>Klick den Biber an um das Spiel zu starten.</p>
        </div> );
    }
    if (this.state.screen === 'game') {
      return (
        <div>
          <Game settings={this.state.settings} />
          <img className="beaver" src="./images/biber.png" height="20%" width="20%" alt=""/>
        </div> );
    }
    if (this.state.screen === 'settings') {
      return (
        <div>
          <Settings 
            settings={this.state.settings} 
            updateSettings={this.updateSettings.bind(this)}
            cancelSettings={this.cancelSettings.bind(this)} />
        </div>
      )
    }
  }

  render() {
    return (
      <div className="Root">
        <div className="Menu">
          <img src="./images/menu.png" alt="" height="50" width="50" onClick={this.clickMenu.bind(this)} />
        </div>
        <div className="App">
          <h1>Rechnen mit Bobby Biber</h1>
          {this.gameLayout()}
        </div>
      </div>
    );
  }
}


export default App;
