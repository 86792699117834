import React, { Component } from 'react';
import Counter from './components/counter.jsx';
import Form from './components/form.jsx';
import Statistics from './components/stats.jsx';
import Output from './components/output.jsx';

export default class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current:  {No1: 0, No2: 0, Result: 0, Modulo: 0, Arithmetic: ""},
      original: {No1: 0, No2: 0, Result: 0, Modulo: 0, Arithmetic: ""},
      input:    {No1: 0, No2: 0, Result: 0, Modulo: 0, Arithmetic: ""},
      hide: 0,
      correct: false,
      countCorrect: 0,
      counter: 0,
      startTime:  {date: new Date(), seconds: Date.now()}
    };
  }

  componentDidMount() {
    this.createRandomNumbers();
  }

  createRandomNumbers() {
    var No1 = undefined;
    var No2 = undefined;
    var Result = undefined;
    var Modulo = undefined;
    var temp = undefined;

    while (Result === undefined || Result < this.props.settings.resultRange.min || Result > this.props.settings.resultRange.max || No1 < this.props.settings.numberRange[0].min || No1 > this.props.settings.numberRange[0].max) {
      var Arithmetic = this.getArithmetic();
      //calculation varies depending on the arithmetic
      switch (Arithmetic) {
        case "/":
          //for Div with integers do Result * No2 to get No1
          Result = Math.round( (this.props.settings.resultRange.max - this.props.settings.resultRange.min) * Math.random() + this.props.settings.resultRange.min);
          //avoid endless loop when No2 range is just 0 -> set it to 1
          if (this.props.settings.numberRange[1].max === 0 && this.props.settings.numberRange[1].min === 0) {
            No2 = 1;
          } else {
            //div by 0 is not allowed and requires re-calc
            while (No2 === undefined || No2 === 0) {
              No2 = Math.round( (this.props.settings.numberRange[1].max - this.props.settings.numberRange[1].min) * Math.random() + this.props.settings.numberRange[1].min);
            }
          }
          No1 = this.getResult(Result, No2, Arithmetic);
          break;
        case "mod":
          //for mod do *, No1 will be decimal with one digit, flip result with No1
          Result = Math.round( (this.props.settings.resultRange.max - this.props.settings.resultRange.min) * Math.random() + this.props.settings.resultRange.min);
          //avoid endless loop when No2 range is just 0 -> set it to 1
          if (this.props.settings.numberRange[1].max === 0 && this.props.settings.numberRange[1].min === 0) {
            No2 = 1;
          } else {
            //div by 0 is not allowed and requires re-calc
            while (No2 === undefined || No2 === 0) {
              No2 = Math.round( (this.props.settings.numberRange[1].max - this.props.settings.numberRange[1].min) * Math.random() + this.props.settings.numberRange[1].min);
            }
          }
          temp = this.getResult(Result + Math.random(), No2, Arithmetic);
          Modulo = temp.mod;
          No1 = temp.result;
          break;
        default:
          No1 = Math.round( (this.props.settings.numberRange[0].max - this.props.settings.numberRange[0].min) * Math.random() + this.props.settings.numberRange[0].min);
          No2 = Math.round( (this.props.settings.numberRange[1].max - this.props.settings.numberRange[1].min) * Math.random() + this.props.settings.numberRange[1].min);
          Result = this.getResult(No1, No2, Arithmetic);
          break;
      }
    }
    var hide = this.getHide();

    this.setState ({
          current: {No1: No1, No2: No2, Result: Result, Modulo: Modulo, Arithmetic: Arithmetic},
          hide: hide
    });
  }

  getArithmetic() {
    var temp = Math.floor(Math.random() * this.props.settings.arithmeticRange.length);
    return this.props.settings.arithmeticRange[temp];
  }

  getResult(a, b, Arithmetic) {
    switch (Arithmetic) {
      case "+": 
        return a + b; 
      case "-": 
        return a - b; 
      case "*": 
        return a * b; 
      case "/":
        //Div by 0 -> return undefined for another loop
        if (b === 0) return undefined;
        //to get Div for integer numbers, do * and flip Result with No1
        return a * b;
      case "mod":
        if (b === 0) return undefined;
        var result = Math.floor(a * b);
        return ({ result: result, mod: result % b});
      default: 
        return "ERROR in getResult: Unknown Arithmetic '" & Arithmetic & "'";
    }
  }

  getHide () {
    if (!this.props.settings.randomHide) return 2;
    return Math.floor((Math.random() * 3));
  }

/**
 * submit and validation of the entered value
 * @param {*} inputValue
 */
  submitForm(inputValue) {
    var inputInt = undefined;
    var inputIntMod = undefined;
    if (this.state.current.Arithmetic === "mod" && this.state.hide === 2) {
      inputInt = parseInt(inputValue.result, 10);
      inputIntMod = parseInt(inputValue.mod, 10);
    } else {
      inputInt = parseInt(inputValue, 10);
    }    
    var correct = false;
    var input = {
      No1: this.state.current.No1,
      No2: this.state.current.No2,
      Result: this.state.current.Result,
      Modulo: this.state.current.Modulo,
      Arithmetic: this.state.current.Arithmetic
    };
    this.setState({original: JSON.parse(JSON.stringify(input))});

    switch (this.state.hide) {
      case 0:
        correct = (this.state.current.No1 === inputInt);
        input.No1 = inputInt;
        break;
      case 1:
        correct = (this.state.current.No2 === inputInt);
        input.No2 = inputInt;
        break;
      case 2:
        correct = (this.state.current.Result === inputInt);
        input.Result = inputInt;
        if (this.state.current.Arithmetic === "mod") {
          correct = correct && (this.state.current.Modulo === inputIntMod);
          input.Modulo = inputIntMod;
        }
        break;
      default: break;
    }

    this.setState({
      correct: correct,
      input: input,
      counter: this.state.counter + 1
    });
    
    if (correct) {
      this.setState({
        countCorrect: this.state.countCorrect + 1
      });
    }
    this.createRandomNumbers();
  }

  showSuccessPic () {
    //is it the end?
    if (this.state.counter === this.props.settings.questions) {
      //calculate percentage to see if success picture will be shown
      var pct = Math.round(this.state.countCorrect / this.state.counter * 100);
      if (pct >= this.props.settings.noten[0].min && pct <= this.props.settings.noten[0].max) {
        return (<img className="tiger" src="./images/tiger.png" height="100%" width="100%" alt=""/>);
      }
    }  
  }

  printArithmetic(arithmetic) {
    switch (arithmetic) {
      case "mod": return ":";
      case "/" : return ":";
      default: return arithmetic;
    }
  }
 
  render() {
    return (
      <div className="Game">
        <Counter 
          counter={this.state.counter}
          total={this.props.settings.questions} />
        <Form
          current={this.state.current}
          hide={this.state.hide}
          Submit={this.submitForm.bind(this)}
          show={(this.state.counter < this.props.settings.questions)}
          printArithmetic={this.printArithmetic.bind(this)} />
        <Statistics
          counter={this.state.counter}
          total={this.props.settings.questions}
          countCorrect={this.state.countCorrect}
          startTime={this.state.startTime}
          finished={(this.state.counter === this.props.settings.questions)} />
        {this.showSuccessPic()}
        <Output
          original={this.state.original}
          input={this.state.input}
          hide={this.state.hide}
          correct={this.state.correct}
          show={(this.state.counter > 0)}
          printArithmetic={this.printArithmetic.bind(this)} />
      </div>
    );
  }
}
