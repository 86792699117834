import React, { Component } from 'react';

export default class Statistics extends Component {

  calcStartTime() {
    var date = this.props.startTime.date;
    var hh = (date.getHours() < 10) ? "0" + date.getHours() : date.getHours();
    var mm = (date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes();
    var ss = (date.getSeconds() < 10) ? "0" + date.getSeconds() : date.getSeconds();
    return (hh + ':' + mm + ':' + ss);
  }

  calcTime() {
    var time = Math.round((Date.now() - this.props.startTime.seconds) /1000);
    var sec = time % 60;
    var min = (time - sec) / 60;
    sec = (sec < 10) ? "0" + sec : sec;
    min = (min < 10) ? "0" + min : min;
    return (min + ':' + sec);
  }

  calcSuccessPct() {
    //avoid div by 0
    if (this.props.counter === 0) return '0';

    return (Math.round(this.props.countCorrect / this.props.counter * 100));
  }

  render() {
    return (
      <div className="Statistics">
        <table>
            <tbody>
                <tr>
                    <td>Richtig:</td>
                    <td>{this.props.countCorrect}</td>
                    <td>Startzeit:</td>
                    <td>{this.calcStartTime()}</td>
                </tr>
                <tr>
                    <td>Falsch:</td>
                    <td>{this.props.counter - this.props.countCorrect}</td>
                    <td>{(this.props.finished) ? "benötigte Zeit:" : ""}</td>
                    <td>{(this.props.finished) ? this.calcTime() : ""}</td>
                </tr>
                <tr>
                    <td>Summe:</td>
                    <td>{this.props.counter} von {this.props.total}</td>
                </tr>
                <tr>
                    <td>Erfolgsquote:</td>
                    <td>{this.calcSuccessPct()} %</td>
                </tr>
            </tbody>
        </table>
      </div>
    );
  }

}
