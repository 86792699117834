import React, { Component } from 'react';


export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      No1: "",
      No2: "",
      Result: "",
      Modulo: ""
    };
  }

  //initial population of the form inputs
  componentWillReceiveProps(nextProps) {
    this.setState({
      No1: (nextProps.hide !== 0 ) ? nextProps.current.No1 : "",
      No2: (nextProps.hide !== 1 ) ? nextProps.current.No2 : "",
      Result: (nextProps.hide !== 2 ) ? nextProps.current.Result : "",
      Modulo: (nextProps.hide !== 2 ) ? nextProps.current.Modulo : ""
    });
  }

  componentDidUpdate() {
    //set focus on editable form input
     if (this.props.show)
      document.getElementById("input" + this.props.hide).focus();
  }

  //update form inputs with each key press - controlled components
  handleChange0(event) {
    if (this.props.hide === 0) 
      this.setState({No1: this.refs.No1.value});
  }
  handleChange1(event) {
    if (this.props.hide === 1) 
      this.setState({No2: this.refs.No2.value});
  }
  handleChange2(event) {
    if (this.props.hide === 2) 
      this.setState({Result: this.refs.Result.value});
  }
  handleChange3(event) {
    if (this.props.hide === 2) 
      this.setState({Modulo: this.refs.Modulo.value});
  }

  render() {
    if (!this.props.show) return <span />;
    var mod = "";
    if (this.props.current.Arithmetic === "mod") {
      mod = <span> R <input id="input3" type="number" value={this.state.Modulo} ref="Modulo" onChange={this.handleChange3.bind(this)} /> </span>
    }
    return (
      <div className="Form">
        <form onSubmit={this.handleInput.bind(this)}>
          <input id="input0" type="number" value={this.state.No1} ref="No1" onChange={this.handleChange0.bind(this)} />
          <bold> {this.props.printArithmetic(this.props.current.Arithmetic)} </bold>
          <input id="input1" type="number" value={this.state.No2} ref="No2" onChange={this.handleChange1.bind(this)} />
          <bold> = </bold>
          <input id="input2" type="number" value={this.state.Result} ref="Result" onChange={this.handleChange2.bind(this)} />
          {mod}
          &nbsp;
          <button className="btn">Prüfen</button>          
        </form>
      </div>
    );
  }

  handleInput(event) {
      event.preventDefault();
      //return only the value which has to be enterd
      switch (this.props.hide) {
        case 0:
          (this.refs.No1.value !== "") ? this.props.Submit(this.refs.No1.value) : this.noNumber();
          break;
        case 1:
          (this.refs.No2.value !== "") ? this.props.Submit(this.refs.No2.value) : this.noNumber();
          break;
        case 2:
          //special handling for Modulo
          if (this.props.current.Arithmetic === "mod") {
            if ((this.refs.Result.value === "") || (this.refs.Modulo.value === "")) {
              this.noNumber();
            } else {
              this.props.Submit( { result: this.refs.Result.value, mod: this.refs.Modulo.value} );
            }
          } else {
            (this.refs.Result.value !== "") ? this.props.Submit(this.refs.Result.value) : this.noNumber();
          }          
          break;
        default:
          break;
      }
  }

  noNumber() {
    alert("Bitte Zahl eingeben");
    document.getElementById("input" + this.props.hide).focus();
  }
  
}
