import React, { Component } from 'react';
import OutputRow from './output-row.jsx';

export default class Output extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 1,
      log: []
    };
  }

  componentWillReceiveProps  (nextprops) {
    if (nextprops.show) {
      const Style = {
          color: (nextprops.correct) ? 'green' : 'red'
      };
      var arithmetic = nextprops.printArithmetic(nextprops.input.Arithmetic);
      var logtext = nextprops.input.No1 + ' ' + arithmetic + ' ' + nextprops.input.No2 + ' = ' + nextprops.input.Result;
      var isModulo = (nextprops.input.Arithmetic === "mod");
      if (isModulo) {
        logtext += " R " + nextprops.input.Modulo;
      }

      if (nextprops.correct) {
        logtext += " ist richtig :-)";
      }
      else {
        if (isModulo) {
          logtext += " ist FALSCH!!! Korrekt wäre: " + nextprops.original.No1 + ' ' + arithmetic + ' ' + nextprops.original.No2 + ' = ' + nextprops.original.Result + ' R ' + nextprops.original.Modulo + ', denn ' + nextprops.original.No1 + ' = (' + nextprops.original.Result + ' * ' + nextprops.original.No2 + ') + ' + nextprops.original.Modulo ;
        } else {
          logtext += " ist FALSCH!!! Korrekt wäre: " + nextprops.original.No1 + ' ' + arithmetic + ' ' + nextprops.original.No2 + ' = ' + nextprops.original.Result;
        }
      };
      
      //Reverse the logging so that latest input is at top
      var newArray = this.state.log;
      newArray.unshift({Style: Style, text: logtext, key: this.state.key});
          
      this.setState({
        log: newArray,
        key: this.state.key + 1
      });
    }
  }

  render() {
    if (!this.props.show) return <span />;

    return (        
      <div className="Output">
        <table>
            <tbody>
                {
                  this.state.log.map((logentry) => {
                    return <OutputRow row={logentry} key={logentry.key} />
                  })
                }               
            </tbody>
        </table>
      </div>
    );
  }

}
