import React, { Component } from 'react';

export default class OutputRow extends Component {
  render() {

    return (
        <tr>
            <td style={this.props.row.Style}>{this.props.row.key + ')  ' + this.props.row.text}</td>
        </tr>
    );
  }
}