import React, { Component } from 'react';

export default class Settings extends Component {
  constructor(props) {
    super(props);

var test = JSON.parse(JSON.stringify(props.settings))
    this.state = {settings: test};
  }

  handleChange(event) {
    var item = this.state.settings;
    if (event.target.type === "checkbox")
      item[event.target.id] = event.target.checked;
    if (event.target.type === "number")
      item[event.target.id] = parseInt(event.target.value, 10);
    this.setState({settings: item });
  }

  handleRanges(event) {
    var item = this.state.settings;
    var attr = event.target.id.split("-");
    if (attr.length === 2)
      item[attr[0]][attr[1]] = parseInt(event.target.value, 10);
    else
      item[attr[0]][attr[1]][attr[2]] = parseInt(event.target.value, 10);
    this.setState({settings: item });
  }

  handleMultiSelect(event) {
    var item = this.state.settings;
    item.arithmeticRange = [];
    for (var i = 0; i< event.target.selectedOptions.length; i++) {
        item.arithmeticRange.push(event.target.selectedOptions[i].value)
    }
    this.setState({settings: item });
  }

  render() {
    return ( 
      <div className="settings-form">
        <form onSubmit={this.handleInput.bind(this)}>
            <table>
                <tbody>
                    <tr>
                        <td colSpan="2">Anzahl Aufgaben</td>
                        <td><input id="questions" type="number" value={this.state.settings.questions} onChange={this.handleChange.bind(this)} /></td>
                        <td><input id="randomHide" type="checkbox" className="regular-checkbox" checked={this.state.settings.randomHide} onChange={this.handleChange.bind(this)} /></td>
                        <td>Zufälliges Ausblenden</td>
                    </tr>
                    <tr>
                        <td>Zahl 1:</td>
                        <td>von</td>
                        <td><input id="numberRange-0-min" type="number" value={this.state.settings.numberRange[0].min} onChange={this.handleRanges.bind(this)} /></td>
                        <td>bis</td>
                        <td><input id="numberRange-0-max" type="number" value={this.state.settings.numberRange[0].max} onChange={this.handleRanges.bind(this)} /></td>
                    </tr>
                    <tr>
                        <td>Zahl 2:</td>
                        <td>von</td>
                        <td><input id="numberRange-1-min" type="number" value={this.state.settings.numberRange[1].min} onChange={this.handleRanges.bind(this)} /></td>
                        <td>bis</td>
                        <td><input id="numberRange-1-max" type="number" value={this.state.settings.numberRange[1].max} onChange={this.handleRanges.bind(this)} /></td>
                    </tr>
                    <tr>
                        <td>Ergebnis:</td>
                        <td>von</td>
                        <td><input id="resultRange-min" type="number" value={this.state.settings.resultRange.min} onChange={this.handleRanges.bind(this)} /></td>
                        <td>bis</td>
                        <td><input id="resultRange-max" type="number" value={this.state.settings.resultRange.max} onChange={this.handleRanges.bind(this)} /></td>
                    </tr>
                    <tr>
                        <td colSpan="2">Rechenarten:</td>
                        <td><select multiple size="5" id="arithmetic" value={this.state.settings.arithmeticRange} onChange={this.handleMultiSelect.bind(this)}>
                            <option>+</option>
                            <option>-</option>
                            <option>*</option>     
                            <option>/</option>
                            <option>mod</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                      <td />
                      <td colSpan="2"><button className="btn settings cancel" type="button" onClick={this.props.cancelSettings}>Cancel</button></td>
                      <td colSpan="2"><button className="btn settings ok">OK</button></td>
                    </tr>
                </tbody>
            </table>
        </form>
      </div> 
    );
  }

  handleInput(event) {
      event.preventDefault();
      this.props.updateSettings(this.state.settings);
  }
}
